.popupContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: auto;
    width: 45vw;
    background-color: white;
    border-radius: 1vh;
    position: absolute;
    top: 20vh;
    left: 27.5vw;
    display: flex;
}

.close {
    position: absolute;
    height: 2vh;
    width: 2vh;
    align-self: last baseline;
    margin-top: 3vh;
    margin-right: 3vh;
}

.revTitle {
    margin-top: 4vh;
    padding-left: 2.5vw;
    font-size: 2.75vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
}

.paramText {
    margin-top: 1vh;
    padding-left: 1vw;
    font-size: 2.75vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    line-height: 5vh;
}


.dropDownContainer {
    background-color: #ECECEC;
    width: max-content;
    border-radius: 2vh;
    box-shadow: 0px 5px 7px 0px #262d3b0b;
    z-index: 2;
    margin-top: 1vh;
    margin-left: 2.5vw;
}


.reviewOpt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 2vh;
    height: 5vh;
}

.reviewOpt h2 {
    font-family: Assistant;
    color: #262D3B;
    font-size: 2.5vh;
    line-height: 2.5vh;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.reviewOpt img {
    height: 1.25vh;
    margin-left: 1vw;
    align-self: last baseline;
    margin-bottom: 1.75vh;
    padding-right: 1vw;

}

.searchFeedbacks {
    position: absolute;
    left: 31vw;
    top: 9.5vh;
}

.searchFeedbacks textarea {
    width: 8vw;
    height: 4vh;
    border: 0;
    border-bottom: 1px solid #262D3B;
    box-sizing: border-box;
    font-size: 2.5vh;
    padding-right: 1vw;
    resize: none;
    overflow: hidden;
    cursor: pointer;
}

.searchFeedbacks img {
    height: 3vh;
    position: absolute;
    margin-top: 0.5vh;
    margin-left: -1vw;
}

.skillsReview {
    display: flex;
    position: absolute;
    flex-direction: row;
    width: 40vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    flex-wrap: wrap;
    margin-top: 14vh;
    max-height: 25vh;
    overflow-y: auto;
}

.skill {
    height: 4vh;
    width: fit-content;
    border-radius: 2vh;
    margin-top: 1vh;
    margin-right: 1vh;
    cursor: pointer;
}


.skill h2 {
    font-size: 2.5vh;
    margin-top: 0.25vh;
    padding-left: 0.75vw;
    padding-right: 0.75vw;

}

.validation {
    color: red;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2.25vh;
    position: absolute;
    align-self: last baseline;
    margin-top: 39vh;
    margin-right: 2.5vw;



}

.sendReview {
    height: 5vh;
    width: max-content;
    background-color: #262D3B;
    border-radius: 5vh;
    display: flex;
    flex-direction: row;
    align-self: last baseline;
    margin-right: 2.5vw;
    margin-top: 42vh;
    position: absolute;
    cursor: pointer;
}

.sendReview h2 {
    color: white;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2.75vh;
    padding-left: 1vw;
    padding-right: 1vw;
    line-height: 5vh;
}