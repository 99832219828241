.formContainer {

    margin-top: 2vh;
    margin-left: 2vw;
    height: 88vh;
}

.formTitle {
    padding-top: 4vh;
    font-size: 4vh;
    font-family: Assistant;
    font-weight: 600;
}

.registration-form {
    margin-top: 2vh;
}

.form-group {
    margin-bottom: 2vh;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    color: #262D3B;
    font-family: Assistant;

}

.formContainer input[type="text"],
.formContainer select,
.formContainer button,
.formContainer .occupation-options label {
    width: 35vw;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 2vh;
    font-size: 3vh;
}

.gender {
    display: flex;
    flex-direction: row;
    margin-left: -1vw;

}

.gender label {
    margin-left: 1vw;
}
.gender input{
    background-color:'pink'
}

.next h2 {
    color: #262D3B;
    cursor: pointer;
    font-size: 3.5vh;
    font-family: Assistant;
    font-weight: 600;

}

.occupation-options {
    display: flex;
    flex-direction: column;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Additional styling for error messages */