.staff-course-list {
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.staff-course-list .prompt-generation-button {
  margin: 5px 0;
}

.staff-course-list i {
  font-style: italic;
}

.staff-course-in-list,
.staff-course-in-list-unit,
.staff-course-in-list-lesson {
  border-radius: 5px !important;
}

.staff-course-in-list-name,
.staff-course-in-list-unit-name,
.staff-course-in-list-lesson-name {
  display: flex;
  font-weight: bold !important;
  color: #282c34 !important;
}

.staff-course-in-list-name input {
  background: transparent;
  resize: none;
  width: 800px;
  border: none;
  font-size: 1rem;
  color: #282c34;
  font-weight: bold;
}

.staff-course-in-list-name input::placeholder {
  color: #555;
  font-weight: normal !important;
}

.staff-course-in-list-name svg,
.staff-course-in-list-unit-name svg,
.staff-course-in-list-lesson-name svg,
.staff-course-in-list-description svg {
  margin-inline-end: 5px;
}

.staff-course-in-list-units,
.staff-course-in-list-lessons {
  margin: 5px 0;
}

.staff-course-in-list-info-page {
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 0 10px 0;
  border: 1px dashed #282c34;
}

.staff-course-in-list-details {
  margin-bottom: 10px;
}

.staff-course-in-list-description {
  display: flex;
  align-items: center;
  background-color: #e6f4f1;
  padding: 10px 15px;
  border-radius: 5px;
  /* margin-bottom: 5px; */
}

.prompt-generation-section .scenario-state-list {
  background-color: #c6edfb;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  color: #282c34;
}

.order-marker {
  box-sizing: border-box;
  background-color: #ffffff50;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  border: 1px solid #282c34;
}

.input-in-listed-scenario {
  position: relative;
  margin-right: 10px;
}

.input-in-listed-scenario input {
  width: 275px;
  margin: 0;
  border: 1px solid #282c34;
  border-radius: 5px;
  background-color: #fff;
}

.input-in-listed-scenario label {
  font-size: 10px;
  position: absolute;
  bottom: 20px;
}

.input-in-listed-scenario input:disabled {
  color: #ccc;
  background-color: #ffffff95;
  border-color: #ccc;
}