.megaInfoContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #262d3b62;
    z-index: 33;
    top: 0;
}

.homePopupContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 75vw;
    background-color: white;
    border-radius: 1vh;
    /* position: absolute; */
    /* top: 15vh; */
    /* left: 17.5vw; */
}

.exitInfo.MuiButtonBase-root {
    position: absolute;
    top: 0;
    right: 0;
}

.no-tokens-paper * {
    font-family: Assistant;
}

.homeConvTitle {
    font-size: 22px;
    color: #262D3B;
    margin-top: 0;
    font-family: Assistant;
    font-weight: 600;
    text-decoration: underline;
    white-space: pre-wrap;
    text-align: justify;
    text-justify: inter-word;
}

.homeConvDescription {
    margin: 15px 0 5px 0;
    font-size: 22px;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    white-space: pre-wrap;
    text-align: justify;
    text-justify: inter-word;
}

.homeInfoskills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.homeInfoskills * {
    font-family: Assistant;
    font-weight: normal;
}

.homeInfoskill {
    height: 30px;
    width: fit-content;
    border-radius: 15px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeInfoskill h2 {
    font-size: 18px;
    padding: 0 10px;

}

.infoButtonContainer  {
    position: absolute;
    bottom: 0;
    right: 0;
}

.homeInfoDiv {
    height: calc(70vh - 155px);
    margin: 40px 0;
    overflow-y: auto;
}


.homeOp {
    display: flex;
    flex-direction: row;
    padding-left: 2vw;
    align-items: center;
}

.homeOp img {
    height: 2vh;
}

.homeOp h2 {
    font-size: 3vh;
    font-family: Assistant;
    font-weight: 400;
    padding-left: .5vw;
}

.xOp {
    padding-right: 2vw;
    height: 2vh;

}