.buttonComponent {
    height: 30px;
    width: max-content;
    padding: 7px 10px;
    background-color: #262D3B;
    border-radius: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)

}

.buttonComponent h2 {
    color: white;
    font-family: Assistant;
    font-weight: 600;
    font-size: 22.5px;
    /* line-height: 30px; */
    margin-inline-start: 10px;
}

.buttonComponent img {
    height: 30px;
    /* width: 25px; */
    margin-inline-start: 5px;
}