.option-bar-container {
    display: flex;
    flex-direction: row;
    margin-left: -10vw;

}

.reload-container {
    align-self: center;
    margin-right: 3vw;
    cursor: pointer;
    z-index: 3;
    display: flex;
    align-items: center;
}

.reload-icon {
    height: 7vh;
    width: 7vh;
    border-radius: 5vh;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}

.reload-text {
    text-align: center;
    color: #262D3B;
    font-size: 2.5vh;
    margin-left: 0.5vw;
}

.chat-bar {
    background-color: #262D3B;
    height: 12vh;
    width: 25vw;
    border-radius: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 1;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68);
}

.icon {
    height: 4.75vh;
    align-self: center;
    cursor: pointer;

}