.assistantLoad {
    height: 27.5vh;
    width: 25vh;
    align-self: center;

}

.topLoader {
    height: 110px;
    display: block;
    background-color: #262d3b;
    position: absolute;
    top: 0vh;
    left: 0vh;
    right: 0vh;
    display: flex;
    flex-direction: column;


}

.chat-loader-paper * {
    font-family: Assistant;
}

.chat-loader-paper .homeInfoDiv {
    margin: 130px 20px 0 20px;
    flex: 1;
    height: max-content;
} 

.loadComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px auto 2px auto;
}

.iconLoad {
    height: 1vh;
    width: 1vh;
    margin-top: 2vh;
    margin-left: 1vh;
    /* position: absolute; */
    align-self: center;

}


.loadTitle {
    font-size: 30px;
    color: white;
    font-family: Assistant;
    font-weight: 600;
    align-self: center;
    margin: 2px auto;
}


.backLoad {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* align-self: first baseline; */
    left: 20px;
    position: absolute;
    cursor: pointer;
    z-index: 5;
}

.backLoad img {
    height: 20px;
}

.backLoad h2 {
    font-size: 22.5px;
    color: white;
    padding-left: 15px;
    font-weight: normal;
}


@keyframes ldio-wbsk1rjduxb {
    0% {
        transform: translate(1.139vh, -7px) scale(0);
    }

    25% {
        transform: translate(1.139vh, -7px) scale(0);
    }

    50% {
        transform: translate(1.139vh, -7px) scale(1);
    }

    75% {
        transform: translate(4vh, -7px) scale(1);
    }

    100% {
        transform: translate(7vh, -7px) scale(1);
    }
}

@keyframes ldio-wbsk1rjduxb-r {
    0% {
        transform: translate(7vh, -7px) scale(1);
    }

    100% {
        transform: translate(7vh, -7px) scale(0);
    }
}

@keyframes ldio-wbsk1rjduxb-c {
    0% {
        background: #f7ddd9
    }

    25% {
        background: #e3e9dd
    }

    50% {
        background: #c8c7d6
    }

    75% {
        background: #cae0e4
    }

    100% {
        background: #f7ddd9
    }
}

.ldio-wbsk1rjduxb div {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: translate(60px, -7px) scale(1);
    background: #f7ddd9;
    animation: ldio-wbsk1rjduxb 5.88235294117647s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-wbsk1rjduxb div:nth-child(1) {
    background: #cae0e4;
    transform: translate(56px, -7px) scale(1);
    animation: ldio-wbsk1rjduxb-r 1.4705882352941175s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-wbsk1rjduxb-c 5.88235294117647s infinite step-start;
}

.ldio-wbsk1rjduxb div:nth-child(2) {
    animation-delay: -1.4705882352941175s;
    background: #f7ddd9;
}

.ldio-wbsk1rjduxb div:nth-child(3) {
    animation-delay: -2.941176470588235s;
    background: #cae0e4;
}

.ldio-wbsk1rjduxb div:nth-child(4) {
    animation-delay: -4.411764705882352s;
    background: #c8c7d6;
}

.ldio-wbsk1rjduxb div:nth-child(5) {
    animation-delay: -5.88235294117647s;
    background: #e3e9dd;
}

.loadingio-spinner-ellipsis-3luurm4d5ky {
    width: 40px;
    height: 0px;
    overflow: hidden;
}

.ldio-wbsk1rjduxb div {
    box-sizing: content-box;
}