.prompt-generation-container {
  margin: 20px 0px;
  display: flex;
  color: #282c34;
  flex-direction: column;
  scroll-behavior: smooth;
  position: relative;
  flex: 1;
}

.admin-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.admin-page div.page-header {
  position: absolute;
  top: 0;
}

.admin-page-sidebar-and-content {
  flex: 1;
  position: absolute;
  top: 12vh;
  left: 0;
  bottom: 0;
  right: 0;
}

.admin-page-content {
  overflow: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 265px;
  right: 0;
  bottom: 0;
}

.admin-sidebar {
  background-color: #0ae5a170;
  border-right: 1px solid #ccc;
  width: 215px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: #00000030 3px 0px 5px 0px;
}

.admin-page *:not(pre) {
  font-family: Assistant !important;
}

.admin-page h1, .admin-page h2, .admin-page h3, .admin-page h4, .admin-page h5, .admin-page h6, .admin-page p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.prompt-generation-container button {
  border: unset;
  cursor: pointer;
}

.MuiDialog-paper .generate-tests-button {
  margin: 10px auto 20px auto;
  border: none;
}

.generate-popup-number-input {
  margin-top: 8px;
  color: #282c34;
  height: 25px;
  font-size: 15px;
  border: 2px solid #282c34;
  border-radius: 5px;
  padding-left: 5px;
}

button.MuiIconButton-root.prompt-generation-create-tests-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  background: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.MuiIconButton-root.prompt-generation-create-tests-button:disabled {
  background-color: #ccc;
}

.skill-in-list .toggle-expand-button,
.add-new-skill-btn.toggle-expand-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0ae5a1;
}

.add-new-skill-btn {
  margin: 5px 10px;
}

.skill-in-list-actions {
  display: flex;
}

.toggle-expand-button svg {
  height: 15px;
}

.toggle-expand-button:disabled {
  cursor: default;
}

.prompt-generation-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100px;
}

.skill-in-list {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #eee;
  padding: 5px 10px;
  border: 2px solid transparent;
  border-bottom: 2px solid #ccc;
  cursor: pointer;
  transition: 0.3s;
  box-sizing: border-box;
  border-radius: 5px;
}

.prompt-generation-inactive .skill-in-list,
.prompt-generation-inactive .skill-in-list:hover {
  background-color: #eee;
  cursor: default;
}

.skill-in-list:hover {
  background-color: #ddd;
}

.skill-in-list-selected {
  border: 2px solid #282c34;
}

.skill-in-list.skill-in-list-actions-variant:hover {
  background-color: #eee;
  cursor: default;
}

.classification-questions-search-and-filter {
  display: flex;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  align-items: center;
}

.classification-questions-search-and-filter input {
  border: 2px solid #282c34;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  width: 70%;
  height: 30px;
}

.prompt-generation-section {
  margin-top: 20px;
  width: 800px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
}

.no-border-bottom {
  border-bottom: none;
}

.prompt-generation-prev-definitions {
  margin: 10px 0 10px 0;
  background-color: #ddd;
  border-radius: 5px;
}

.prompt-generation-prev-definitions li {
  padding: 7px 0 7px 0;
  margin: 0 5px 0 5px;
  border-bottom: 1px solid #777;
}

.prompt-generation-inactive {
  opacity: 0.4;
}

.prompt-generation-inactive button {
  cursor: default !important;
}

button.prompt-generation-button:disabled {
  background-color: #aaa;
  cursor: default;
}

.prompt-generation-input {
  width: 100%;
  position: relative;
}

.prompt-generation-input-error .prompt-generation-textarea {
  border: 3px solid #fa7d5f;
}

.prompt-generation-input-error-message {
  color: #fa7d5f;
  font-size: 13px;
  height: 14px;
}

.prompt-generation-textarea {
  resize: none;
  border: 3px solid #282c34;
  border-radius: 10px;
  position: relative;
  height: 150px;
  width: 100%;
  box-sizing: border-box;
}

button.prompt-generation-button {
  width: fit-content;
  background-color: #282c34;
  outline: none;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  margin: 5px;
  transition: 0.3s;
  padding: 10px 15px;
  border: none;
}

.prompt-generation-button:hover,
.prompt-generation-button:focus {
  cursor: pointer;
  background-color: #14161a;
}

.prompt-generation-test-wrapper {
  margin-bottom: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #eee;
}

.prompt-generation-test-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.prompt-generation-test {
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
}

.prompt-generation-test-conversation {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.prompt-generation-test-conversation-item label {
  margin-bottom: unset;
}

.prompt-generation-test-conversation-item textarea {
  height: fit-content;
  width: 175px;
  background-color: #ffffff;
  border-radius: 5px;
  resize: none;
}

.prompt-generation-test-conversation-item-b {
  align-self: flex-end;
}

.prompt-generation-test-conversation-item-b textarea {
  background-color: rgb(95, 250, 202);
}

.prompt-generation-test-expected-result select {
  width: 80px;
  font-size: 20px;
  padding: 2px;
  border-radius: 5px;
}

.prompt-generation-test-expected-result select:disabled {
  appearance: none;
}

.prompt-generation-test-conversation button.prompt-generation-add-another {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  margin: 2px;
}

.prompt-generation-test-conversation button.prompt-generation-add-another svg {
  height: 20px;
}

.prompt-generation-container * {
  font-family: Assistant;
}

.prompt-generation-result-markdown {
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
}

button.prompt-generation-expand-table-row {
  border-radius: 50%;
}

.prompt-generation-section .MuiTableCell-root {
  padding: 5px;
}

.conv-hist-markdown {
  max-width: 950px;
  text-wrap: wrap;
}
