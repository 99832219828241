.EditorDialogActions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
}

.EditorDialogButtonsList {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 20px;
}

.EditorDialogButtonsList button {
  margin-bottom: 20px;
}
