.message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 35vh;
    width: 85%;
    font-size: 1.5vw;
    align-items: center;
}

.flexbox-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 3vh;
    align-items: center;
    width: 85%;
}

.filter-item {
    display: flex;
    flex: 2;
}

.filter-bar {
    margin: 2vh 0vh 2vh 0vh;
    width: 100px;
}

.rectangle-object {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1vw;
    border-radius: 20px;
    height: 30px;
    width: 15vw;
    padding: 5px 10px 5px 10px;
}

.show-graph {
    color: white;
    font-family: Assistant;
    font-weight: 400;
    font-size: 1vw;
    font-align: center;
    padding: 0px;
    margin-left: 1vw;
    align-items: center;
    height: 30px;
    width: 15vw;
    background-color: #262D3B;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}

.conv-turns-wrapper button:disabled, .conv-turns-wrapper button[disabled]{
    color: #f0f0f0;
    font-family: Assistant;
    font-weight: 400;
    font-size: 1vw;
    font-align: center;
    padding: 0px;
    margin-left: 1vw;
    align-items: center;
    height: 30px;
    width: 15vw;
    background-color: #d1d1d1;
    border-radius: 20px;
    cursor: not-allowed;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}
