.endPopupContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 45vw;
    background-color: white;
    border-radius: 1vh;
    position: absolute;
    top: 25vh;
    left: 27.5vw;
}

.statusIcon {
    height: 25vh;
    width: 25vh;
    position: absolute;
    margin-top: 12.5vh;
    z-index: 5;
}

.infoDiv {
    max-height: 70vh;
    overflow-y: auto;
    width: 44.5vw;

}

.exitEndOps {
    position: absolute;
    margin-top: 2vh;
    margin-left: 42.5vw;
}

.xOp {
    padding-right: 2vw;
    height: 2vh;
    cursor: pointer;

}

.endTitle {
    color: #262D3B;
    font-family: Assistant;
    font-weight: bold;
    text-align: center;
    padding-left: 2.5vw;
    width: 40vw;
    font-size: 4vh;

}

.endText {
    margin-top: 1vh;
    padding-left: 2.5vw;
    width: 40vw;
    font-size: 3vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    white-space: pre-wrap;
    text-align: center;
    text-justify: inter-word;
    height: 15vh;
    overflow-y: auto;
    margin-bottom: 0;
}

.centerButton {
    align-self: center;
    margin-top: 2vh;
}

.infoEndChat {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    align-self: center;

}

.infoEndChat img {
    height: 2.5vh;
}

.infoEndChat h2 {
    line-height: 2.5vh;
    font-size: 2.5vh;
    font-family: Assistant;
    color: #262D3B;
    padding-left: .5vw;

}

.feedbackInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    width: 40vw;
    height: 15vh;
    background-color: #f3f3f3;
    margin-top: -9vh;
    border-radius: 1vh;

}

.feedbackInput textarea {
    width: 100%;
    height: auto;
    min-height: 15vh;
    border-radius: 1vh;
    border: 0;
    background-color: #f3f3f3;
    padding: 1vh;
    font-size: 2.5vh;
    line-height: 1.5;
    resize: none;
    box-sizing: border-box;
}

.star-rating {
    display: flex;
    direction: row;
    align-self: center;
}

.star-rating button {
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 1vh;
    padding-right: 1vh;
    margin-top: -1vh;

}

.star-rating .star {
    font-size: 2rem;
    color: gray;
}

.star-rating .on .star {
    color: #0AE5A1;
}

.star-rating .off .star {
    color: lightgray;
}

.validations {
    color: red;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2.25vh;
    position: absolute;
    margin-top: 38vh;
    margin-left: 2.5vw;
    margin-right: 2.5vw;



}