.megaInfoContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #262d3b62;
    z-index: 33;
    top: 0;
}

.popupContainerForm {
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: auto;
    width: 45vw;
    background-color: white;
    border-radius: 1vh;
    position: absolute;
    top: 25vh;
    left: 27.5vw;
    display: flex;
}

.doneImg {
    margin-top: 5vh;
    height: 11vh;
    width: 13vh;
    align-self: center;
}

.centerdForm {
    align-self: center;
    align-content: center;
}

.formSubmitTxt {
    font-size: 3.5vh;
    font-weight: 800;
    margin-top: 3vh;
    align-self: center;
    text-align: center;
}

.formSubmitTxtS {
    font-size: 3vh;
    font-weight: 600;
    margin-top: 2vh;
    align-self: center;
    text-align: center;
    padding-left: 2vw;
    padding-right: 2vw;

}