*:focus {
  outline: none;
}

.chat-page {
  height: 100vh;
  width: 40vw;
}

.container {
  display: flex;
  height: 100vh;
  /*Align items to the top */
  align-items: flex-start;
}

.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: first baseline;
  margin-top: 20px;
  margin-left: 35px;
  cursor: pointer;
  width: fit-content;
}

.back img {
  height: 22.5px;
}

.back h2 {
  font-size: 22.5px;
  color: #262D3B;
  padding: 0;
  padding-left: 15px;
  font-weight: normal;
  font-family: Assistant;
}

.centered {
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  z-index: 2;
  width: 60vw;
}

.right {
  width: 40vw;
}

.avatar-image {
  height: 70vh;
  border-radius: 50vh;
  box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68);
}

.avatarDisplay {
  margin: auto;
  position: relative;
}

.chatBar {
  margin-bottom: 2.7vh;
}