.chapterSumContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 25px 50px;
}

.chapterSumContainer .back {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
}

.info-page-content {
    padding: 0 30px;
    width: 80%;
    position: relative;
}

.info-page-title, .info-page-md {
    color: #262D3B;
}

.info-page-title {
    font-size: 24px;
}

/* .chapterSum {
    background-color: #0ae5a078;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.58);
    width: 70vw;
    margin-left: 15vw;
    margin-right: 15vw;
    height: fit-content;
    margin-top: 4vh;
    border-radius: 1vh;

}

.chapterSum h2 {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    font-size: 3.25vh;
    color: #262D3B;
    font-weight: 600;
}

.subCategory {
    width: 70vw;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 5vh;
}

.subCategory h2 {
    font-size: 3.25vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 600;
}

.subCategory h3 {
    margin-top: 1vh;
    font-size: 3vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    white-space: pre-wrap;
    text-align: left;

}

.subCategory li {
    margin-top: 1vh;
    margin-left: 2vw;
    font-size: 3vh;
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    white-space: pre-wrap;
    text-align: left;
} */