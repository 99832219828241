.posFeedback {
  box-sizing: border-box;
  background: #32fdefdf;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2vh;
  height: max-content;
  min-height: fit-content;
  width: fit-content;
  max-width: 25vw;
  display: flex;
  margin-bottom: 2vh;
}

.posFeedback img {
  height: 4vh;
  align-self: first baseline;
  margin-top: 2vh;
  padding-left: 1vw;
}

.posFeedback h2 {
  font-size: 3vh;
  color: #262D3B;
  padding-left: 0.5vw;
  padding-right: 1vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.negFeedbackText {
  box-sizing: border-box;
  background: #FBF195;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 4vh 2vw;
}

h2 {
  font-size: 3vh;
  color: #262D3B
}

strong {
  font-weight: 600;

}

.link {
  text-decoration: none;
}

.linkText {
  margin-top: 2vh;
  color: #10A0A0;
  cursor: pointer;
}

.tryAgain {
  color: #10A0A0;
  cursor: pointer;
}

.feedbackSymbol {
  justify-self: center;
  align-self: center;
  background-color: transparent;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;

}

.feedbackSymbol img {
  height: 20vh;
  width: fit-content;
  align-self: center;
}

.feedbackSymbol h2 {
  font-size: 2.75vh;
  font-weight: 600;
  color: rgb(10, 229, 160);
  text-decoration: wavy;
  align-self: center;
}


.emotionalAssistant {
  position: absolute;
  top: 16.5vh;
  right: 43.5vw;
  background-color: transparent;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  margin: auto;
  z-index: 4;
}

.emotionalAssistant.fade {
  position: absolute;
  top: 16.5vh;
  right: 43.5vw;
  background-color: transparent;
  justify-content: flex-end;
  opacity: 0;
  margin: auto;
  z-index: 4;
}