.progressBar {
    display: flex;
    flex-direction: row;
    margin-left: 80px;
    margin-top: 10px;
    min-width: 50vw;
}

.progressLine {
    margin-left: 2.85vh;
    width: 0.4vh;
    background-color: #C9D1E3;
    position: absolute;

}

.topics {
    position: absolute;

}

.topicContainer {
    margin-bottom: 4vh;
    display: flex;
    flex-direction: row;

}

.topicNum {
    height: 45px;
    border-radius: 50%;
    background-color: #C9D1E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-right: 22.5px;
    margin-left: -8px;
}

.topicTexts {
    margin-top: 10px;
    margin-left: 25px;
}

.topicTitle {
    color: #262D3B;
    font-family: Assistant;
    font-weight: 600;
    font-size: 25px;
    margin: 0;
}

.topicText {
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    font-size: 3vh;
}

.skillsTitle {
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2.5vh;
    margin-top: 1vh;

}

.topicSkills {
    display: flex;
    flex-direction: row;
    width: 45vw;
    max-height: 10vh;
    flex-wrap: wrap;
    margin-top: .75vh;

}

.skill {
    height: 4vh;
    width: fit-content;
    border-radius: 2vh;
    margin-top: 1vh;
    margin-right: 1vh;
}

.skill h2 {
    font-size: 2.5vh;
    margin-top: 0.25vh;
    padding-left: 0.75vw;
    padding-right: 0.75vw;

}

.lessonsTitle {
    color: #262D3B;
    font-family: Assistant;
    font-weight: 400;
    font-size: 25px;
    /* margin-top: 1vh; */
    /* margin-bottom: 2vh; */
    display: flex;
    align-items: center;

}

.lesson {
    margin-bottom: 4vh;
    cursor: pointer;
}

.lessonContent {
    /* background-color: #eeeeee;
    border-radius: 3vh;
    padding: .75vh;
    padding-left: 1vw;
    padding-right: 1vw;*/
}

.lessonName {
    font-size: 3.25vh;
    margin-left: 3.5vw;
    margin-top: -4vh;

}

button.lessonInfo {
    margin-inline-start: 5px;
}

.lessonInfo img {
    height: 20px;
    width: 20px;
}

.lessonProgressLine {
    margin-left: 1.6vh;
    margin-top: 3.5vh;
    width: 0.3vh;
    background-color: #C9D1E3;
    position: absolute;
    z-index: 2;

}

.lessonNum {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #C9D1E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    margin-right: 20px;

}



.startLessons {
    height: 5vh;
    width: max-content;
    background-color: #262D3B;
    border-radius: 5vh;
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
}

.startLessons h2 {
    color: white;
    font-family: Assistant;
    font-weight: 600;
    font-size: 2.75vh;
    padding-left: 1vw;
    line-height: 5vh;
}

.workImage {
    position: fixed;
    top: 0vh;
    /* height: 50vh; */
    width: 35vw;
    right: 0vw;
}

.unit-stepper .content-stepper .MuiStepConnector-line.MuiStepConnector-lineVertical, .unit-stepper .content-stepper .MuiStep-root .MuiStepContent-root {
    border-left-width: 3px !important;
    border-left-color: #C9D1E3;
}

.unit-stepper .content-stepper .Mui-completed > .MuiStepContent-root, .unit-stepper .content-stepper .Mui-completed + .MuiStepConnector-root .MuiStepConnector-line.MuiStepConnector-lineVertical {
    border-left-color: #10a0a0;
}

.unit-stepper .MuiStepConnector-line.MuiStepConnector-lineVertical, .unit-stepper .MuiStep-root .MuiStepContent-root {
    border-left-width: 4px !important;
    border-left-color: #262D3B;
}
