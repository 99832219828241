.maintenanceContainer {
    height: 88vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.maintenanceIcon {
    width: 30vh;
    height: 30vh;
    background-color: #ECECEC;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15vh;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68);
    margin-top: 7vh;

}

.maintenanceIcon img {
    width: 25vh;
    height: 25vh;
    margin-top: 2.5vh;
}

.maintenanceTitle {
    font-size: 5vh;
    color: #262D3B;
    margin-top: 12.5vh;
    font-weight: 800;
}

.maintenanceTextWrap {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.maintenanceTextWrap h2 {
    font-size: 3vh;
    color: #262D3B;
    margin-top: 1vh;
    font-weight: 600;
}

.maintenanceTextS {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
}

.maintenanceTextS h3 {
    font-size: 2.5vh;
    color: #262D3B;
    font-weight: 400;
}

.maintenanceTextS a {
    font-size: 2.5vh;
    color: #262D3B;
    font-weight: 400;
}