.background {
    height: 100%;
    width: 100%;
    background-color: #009E75;
    overflow: hidden;
    position: absolute;
    z-index: -3;
    overflow-y: hidden;

}

.content {
    position: absolute;
    margin-top: 30vh;
    margin-left: 50vw;
}

.arrows-logo {
    height: 7vh;
    margin-bottom: 1vh;
}


.content h2 {
    font-weight: 600;
    color: white;
    font-family: 'Assistant';
    font-size: 5.5vh;
    text-shadow: 1px 1px 2px rgb(93, 93, 93);
}


.google-button {
    width: fit-content;
    height: 8vh;
    background-color: white;
    border-style: solid;
    border-color: #262D3B;
    border-width: 1px;
    border-radius: .75vh;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.google-button:hover {
    background-color: #ECECEC
}

.google-button:active {
    background-color: #dddddd
}


.google-button img {
    height: 3.5vh;
    margin-left: 1vw;
}

.google-button h3 {
    margin-left: .5vw;
    margin-right: 1vw;

    font-weight: 600;
    color: #262D3B;
    font-family: 'Assistant';
    font-size: 2.5vh;
}

.err{
    margin-top: 2vh;
    width:max-content
}