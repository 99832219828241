.formContainer {
    height: 90vh;
}

.formHeaderLogo 
{
    height:10vh;
    display: flex;
}

.formHeaderLogo img
{
    height:10vh;
    margin: 0 auto;
}