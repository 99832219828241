.courses-page {
    padding-top: 50px;
    box-sizing: border-box;
    flex-grow: 1;
}

.page-header:has(+ .courses-page) {
    height: 12vh !important;
}

.CoursesTitle {
    color: #262D3B;
    align-self: center;
    font-family: Assistant;
    text-wrap: wrap;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
}

.coursesContainer {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 50px auto;
    gap: 20px;
}

.courseCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-top: 5px;
    position: relative;
    height: 100%;
}

a.courseCard, a.courseCard * {
    text-decoration: none;
}

.courseCardWrap {
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 2px 4px 6px 2px rgba(97, 97, 97, 0.35);
    width: 350px;
    height: 275px;
    cursor: pointer;
    transition: transform 0.3s;
    box-sizing: border-box;
    position: relative;
}

.courseCardWrap:hover {
    transform: scale(1.03);
}

.courseCard h3 {
    color: #262D3B;
    align-self: center;
    font-family: Assistant;
    text-wrap: wrap;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin: 15px 5px 10px 5px;
    box-sizing: border-box;
}

.courseCard p {
    color: #262D3B;
    align-self: center;
    font-family: Assistant;
    text-wrap: balance;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin: 5px 0;
    padding: 0 5px;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
}

.course-card-bottom {
    display: flex;
    flex: 1; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 25px;
}

.courseImg {
    height: 50px;
}

.decorativeCardLine {
    background-color: #5eeea6;
    height: 10px;
    width: 40%;
    border-radius: 15px;
    z-index: 10;
    margin-top: 15px;
}

#comingSoon {
    height: 35vh;
    width: 36vh;
    margin-top: -22.75vh;
    margin-left: 24.5vh;
    z-index: 2;
    position: relative;

}