.chat-page {
  height: 100vh;
  width: 40vw;
}

.chat-page * {
  font-family: Assistant;
}

.instructions {
  height: 100vh;
  width: 100vw;
  position: absolute;
  right: 0vw;
  top: 0vh;
  background-color: #262d3b3f;
  z-index: 5;
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chat-header {
  background-color: #ECECEC;
  border-bottom: 0.1vh solid #262D3B;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chat-header h2 {
  color: #262D3B;
  font-family: Assistant;
  font-weight: 600;
  font-size: 2.75vh;
  text-align: center;
  margin-top: auto;
  margin-top: 5.25vh;
  margin-left: 1vw;
}

.did-container {
  margin-right: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.did-container h3 {
  color: #262D3B;
  font-family: Assistant;
  font-weight: 400;
  font-size: 2.25vh;
  text-align: center;
  margin-top: 1vh;
}

.did-toggle {
  margin-top: -1vh;

}

.chat-history {
  background-color: #ECECEC;
  overflow-y: scroll;
  height: 83.3vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5vh;
}

.copyIcon {
  height: 4vh;
  width: 4vh;
  position: absolute;
  margin-top: 7vh;
  margin-left: 36vw;
  cursor: pointer;
}

.copyIcon:hover {
  height: 4vh;
  width: 4vh;
  position: absolute;
  margin-top: 7vh;
  margin-left: 36vw;
  cursor: pointer;
  opacity: 0.6;
}

.feedbackIcon {
  height: 4.5vh;
  width: 4.5vh;
  position: absolute;
  margin-top: 1vh;
  margin-left: 36vw;
  cursor: pointer;
}

.feedbackIcon:hover {
  height: 4.5vh;
  width: 4.5vh;
  position: absolute;
  margin-top: 1vh;
  margin-left: 36vw;
  cursor: pointer;
  opacity: 0.6;
}

.chatContainer {
  align-self: flex-start;
  height: max-content;
  min-height: fit-content;
  width: fit-content;
  max-width: 34vw;
  background-color: #FFFFFF;
  border-radius: 2vh;
  margin-left: 1vw;
  margin-top: 1.5vh;
  margin-bottom: 1vh;
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
}

.chatContainer h3 {
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 1vw;
  padding-right: 1vw;
  color: black;
  font-family: Assistant;
  font-weight: 400;
  font-size: 3vh;
  margin: 0;
}

.feedbacks {
  display: flex;
  flex-direction: row;
  padding-right: 1vw;
  padding-bottom: 2.25vh;
}

.feedbacks img {
  height: 3.5vh;
  cursor: pointer;
  align-self: last baseline;
  padding-left: 0.5vw;
}


.chatFeedbacks {
  align-self: flex-start;
  height: max-content;
  min-height: fit-content;
  width: 36.5vw;
  background-color: #FFFFFF;
  border-radius: 2vh;
  margin-left: 1vw;
  margin-top: 1.5vh;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feedbackBody {
  display: flex;
  flex-direction: row;
}

.assistant {
  height: 7vh;
  align-self: first baseline;
  padding-top: 2.5vh;
  padding-left: 2.5vh;
}

.feedbackTextX {
  height: 2vh;
  padding-top: 2.5vh;
  padding-right: 2.5vh;
  cursor: pointer;
  justify-self: flex-end;
}

.chatFeedbacksText {
  display: flex;
  flex-direction: column;
}

.chatFeedbacksText h2 {
  padding-top: 4vh;
  padding-left: 1vw;
  padding-right: 1vw;
  color: black;
  font-family: Assistant;
  font-weight: 600;
  font-size: 3vh;
}

.chatFeedbacksText h3 {
  padding-bottom: 4vh;
  padding-left: 1vw;
  padding-right: 1vw;
  color: black;
  font-family: Assistant;
  font-weight: 400;
  font-size: 3vh;
}

.cueReview {
  display: flex;
  flex-direction: row;
  margin-left: .5vw;

}

.reviewButton {
  height: 4vh;
  margin-left: 1vw;
  z-index: 3;
}

.reviewButton:hover {
  height: 4vh;
  margin-left: 1vw;
  z-index: 3;
  opacity: 0.6;
}

.botOptions {
  direction: rtl;
  display: flex;
  flex-direction: column;
  margin-right: 1vw;
  margin-left: 1vw;
  margin-top: -.5vh;
}

.chatState {
  font-family: Assistant;
  font-weight: 400;
  font-size: 2.5vh;
  margin: 0;
}

.botReviewButton {
  margin-top: .5vh;
  height: 4vh;
  width: 4vh;
  margin-left: 1vw;
  z-index: 3;

}

.chat-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  width: 40vw;
  height: 15vh;
  background-color: #ECECEC;
  border-top: 0.1vh solid #262D3B;
}

.chat-input textarea {
  margin-inline-start: 1.5vw;
  width: 29vw;
  border-radius: 1.5vh;
  height: 8vh;
  padding: 2vh 1vw;
  border: 0;
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-size: 2.5vh;
  resize: none;
  overflow: hidden;
}

.mic-icon {
  height: 7vh;
  width: 7vh;
  border-radius: 5vh;
  margin-inline-start: 1.5vw;
  cursor: pointer;
}

.send-icon {
  height: 1.25vw;
  margin-inline-start: 1.5vw;
  cursor: pointer;
  z-index: 3;
}

.send-icon-he {
  transform: rotate(180deg);
}

#wait {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 3vh;
}

.bar {
  margin: 0.35vh;
  width: 1vh;
  height: 3vh;
  border-radius: 1vh;

  border: 0.1vh solid #262d3bbc;
  border-left: 0.1vh #2a2a2a;
  background-color: #262D3B;

  animation: 1.5s infinite wait;
}

#bar2 {
  animation-delay: 0.25s
}

#bar3 {
  animation-delay: 0.5s
}

@keyframes wait {
  0% {
    height: 2.5vh
  }

  25% {
    height: 5vh
  }

  50% {
    height: 2.5vh
  }

  100% {
    height: 2.5vh
  }
}