.page-header {
    height: 12vh;
    width: 100vw;
    display: flex;
    flex-flow: row;
    background-color: #262D3B;
    justify-content: space-between;
    position: relative;
    /* {Needed so that it goes over the modal} */
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
}

.header-logo-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-logo-wrapper a {
    display: flex;
    align-items: center;
    height: 100%;
}

.page-header h1 {
    margin: 0;
    font-family: Assistant;
    font-weight: 400;
    font-size: 6vh;
    align-self: center;

}

.header-logo-icon {
    height: 5vh;
    align-self: center;
    margin-left: 3vw;
}

.right-icons {
    margin-right: 3vw;
    align-self: center;
    height: 6vh;
    display: flex;
}

.header-bell-icon {
    height: 4vh;
    cursor: pointer;
    margin-right: 5.5vw;
    align-self: center;
}

.header-reload-icon {
    height: 7vh;
    cursor: pointer;
    margin-right: 5.5vw;
    align-self: center;
}

.header-user-icon {
    height: 6vh;
    cursor: pointer;
}

.userOptions {
    /* background-color: #e900c2; */
    background-color: #262D3B;
    width: 9vw;
    /* height: 8vh; */
    position: absolute;
    align-self: last baseline;
    margin-left: 91vw;
    margin-bottom: -16vh;
    border-bottom-left-radius: 2vh;
    border-bottom-right-radius: 2vh;

}

.userOption {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-top: 2vh;
    height: 6vh;

}

.userOption h2 {
    font-family: Assistant;
    font-weight: 400;
    font-size: 3vh;
    color: white;
    padding-left: 1vw;
    margin-top: -0.25vh;

}

.userOption img {
    height: 3vh;
    margin-top: 0.5vh;
    padding-left: 1vw;
}