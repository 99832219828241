/* Form container */
form.user-info-form {
  /* max-width: 800px; */
  margin: 0 auto;
  /* padding: 40px; */
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.scenario-user-info-form {
  color: #262D3B;
  padding: 40px;
}

form.user-info-form *, .user-info-field-details * {
  font-family: Assistant !important;
}

/* Section Title */
.new-user-form-page h1 {
  font-size: 24px;
  color: #262D3B;
  margin: 5vh auto;
  width: fit-content;
}

/* Label */
form.user-info-form .MuiFormLabel-root {
  font-size: 18px;
  color: #262D3B;
  font-weight: 500;
}

/* TextField Styling */
form.user-info-form .MuiTextField-root {
  width: 100%;
  margin-top: 8px;
  padding: 0;
}

form.user-info-form .MuiInputBase-root {
  padding: 5px;
}

form.user-info-form .MuiInputBase-root input {
  padding: 5px;
}

form.user-info-form .MuiInputBase-root.Mui-focused {
  /* font-size: 16px; */
  outline: none;
}

/* Radio Group Styling */
form.user-info-form .MuiRadio-root {
  color: #555;
}

form.user-info-form .MuiRadio-root.Mui-checked, .user-info-field-details .MuiRadio-root.Mui-checked {
  color: #0ae5a1;
}

form.user-info-form .MuiFormControlLabel-root {
  font-size: 16px;
  color: #333;
}

/* Chip Styling */
form.user-info-form .chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

form.user-info-form .MuiChip-root {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 16px;
}

form.user-info-form .MuiChip-root.Mui-selected {
  background-color: #008080; /* Selected chip color */
  color: white;
}

.new-user-form-page .new-user-form-wrapper {
  margin: 5vh auto;
  width: 900px;
}

/* Navigation Buttons */
.new-user-form-page .nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 900px;
  margin: auto;
}

.new-user-form-page .nav-button {
  font-size: 16px;
  color: #333;
  text-transform: none;
}

.new-user-form-page .nav-button:hover {
  background-color: #f0f0f0;
}


.answer-userinfo-questions-button {
  color: white;
  background: #262D3B;
  border-radius: 2.5vh;
  outline: none;
  border: none;
  padding: 1vh;
  width: 20vh;
  cursor: pointer;
  font-size: 2vh;
}

.answer-userinfo-questions-button:disabled {
  background: #aaa;
  cursor: not-allowed;
}
