.unityAvatarContainer {
    width: 60vw;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#AvatarBackground {
    height: 75vh;
    z-index: 2;
    max-width: 55vw;
    border-radius: 2vh;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.6);

}


#unityWrapper {
    margin-top: 2.5vh;
    align-self: center;
    z-index: 4;
    position: absolute;
}

#botImage {
    z-index: 4;
    margin-top: 5vh;
    position: absolute;
}