.llmButton {
    color: white;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2vh;
    font-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 2vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    align-items: center;
    height: 4vh;
    background-color: #262D3B;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}

.llmButtonRed {
    color: white;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2vh;
    font-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 2vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    align-items: center;
    height: 4vh;
    background-color: red;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}

.currentModel {
    font-family: Assistant;
    font-weight: 400;
    font-size: 2vh;
    margin-top: 2vh;
}

.llmButtons button:disabled, .llmButtons button[disabled]{
    color: #f0f0f0;
    font-family: Assistant;
    font-weight: 400;
    font-size: 2vh;
    font-align: center;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 2vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    align-items: center;
    height: 4vh;
    background-color: #d1d1d1;
    border-radius: 20px;
    cursor: not-allowed;
    box-shadow: 0px 5px 7px 0px rgba(199, 199, 199, 0.68)
}

.addModelHeader {
    margin-bottom: 2vh;
}

.testResultsPopup {
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 73vw;
    max-height: 73vh;
    padding: 3vh 2vw 2vh 2vw;
    background-color: white;
    border-radius: 1vh;
    position: absolute;
    top: 2vh;
    left: 1vw;
    z-index: 5;
    box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.3);
}

.addModelPopup {
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 32vw;
    padding: 3vh 2vw 2vh 2vw;
    background-color: white;
    border-radius: 1vh;
    position: absolute;
    top: 5vh;
    left: 15vw;
    z-index: 5;
    box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.3);
}

.labelInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1vh;
    align-items: center;
}

.addModelLabel, .runTestsLabel {
    margin-right: 1vw;
}

.dropdownSelect {
    border-radius: 20px;
    height: 30px;
    width: 16vw;
    text-align-last: center;
    text-align: center;
}

.addModelInput {
    border: 1px solid gray;
    border-radius: 20px;
    height: 30px;
    width: 14.5vw;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.runTestsInput {
    border: 1px solid gray;
    border-radius: 20px;
    height: 30px;
    width: 3vw;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-right: 1vw;
}

.loadingText {
    text-align: center;
}

