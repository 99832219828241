.userSettingsContainer {
    height: 88vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}


.settingsTabContainer {
    height: 88vh;
    width: 25vw;
    box-shadow: 0px 4px 5px 0px rgba(199, 199, 199, 0.68);
    background-color: #ECECEC;
    overflow-x: hidden;
    overflow-y: hidden;
}

.settingOpts {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;

}

.settingOpt {
    margin-top: 2vh;
    height: 8vh;
    width: 20vw;
    margin-left: 2.5vw;
    display: flex;
    flex-direction: row;
    cursor: pointer;

}


.settingOpt h2 {
    margin-left: .75vw;
    color: #262D3B;
    font-size: 3vh;
    font-family: Assistant;
    font-weight: 600;
    font-size: 3vh
}

.settingOpt h2:hover {
    text-decoration: underline;
}

.settingOpt img {
    height: 4vh;
    z-index: 3;
}

.settingsOptContainer {
    height: 88vh;
    width: 75vw;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-left: 2vw;
    padding-right: 2vw;

}